<template>
  <div>
    <CategoryFilter
      ref="saveForm"
      :category_id="filter.category_id"
      @onSuccess="setFiter"
    />
    <div
      class="d-flex justify-content-between"
      style="margin-bottom: 30px"
    >
      <el-radio-group v-model="reportType">
        <el-radio-button
          v-can="'reports.byAllIncomeProducts'"
          label="allReport"
        >
          Отчёт по всем продуктам
        </el-radio-button>
        <el-radio-button
          v-can="'reports.byAllSoldProducts'"
          label="soldReport"
        >
          Отчет по всем проданным товарам
        </el-radio-button>
        <el-radio-button
          v-can="'reports.byAllResidualProducts'"
          label="residualReport"
        >
          Отчет об остатках
        </el-radio-button>
        <!-- <el-radio-button
          v-can="'reports.byBestSellerProducts'"
          label="bestsellerReport"
        >
          Отчет о самых продаваемых товарах
        </el-radio-button> -->
        <el-radio-button
          v-can="'reports.byNotSoldProducts'"
          label="notSoldReport"
        >
          Отчет о непроданных товарах
        </el-radio-button>
        <el-radio-button
          v-can="'reports.byNotSoldProducts'"
          label="chashSoldReport"
        >
          Отчет по наличным товарам
        </el-radio-button>
        <el-radio-button
          v-can="'reports.goodClients'"
          label="exemplaryClientsReport"
        >
          Отчет об «образцовых клиентах».
        </el-radio-button>
        <el-radio-button
          v-can="'reports.byAllIncomeProducts'"
          label="priceProductsReport"
        >
          Kirim qilingan mahsuloatlar
        </el-radio-button>
      </el-radio-group>

      <b-overlay
        v-if="(reportType !== 'chashSoldReport') && (reportType !== 'priceProductsReport')"
        :show="loadingBtn"
      >
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
          Yuklab olish
        </b-button>
      </b-overlay>
    </div>
    <!-- fiter -->
    <el-form
      v-if="(reportType !== 'chashSoldReport') && (reportType !== 'priceProductsReport')"
      label-position="top"
      label-width="100px"
      :model="filter"
    >
      <el-row
        v-if="reportType !== 'exemplaryClientsReport'"
        class="mb-2"
      >
        <template v-if="reportType !== 'residualReport'">
          <el-col
            :md="8"
            :lg="6"
          >
            <el-form-item
              class="mr-1"
              label="Поставщик"
            >
              <b-form-select
                v-model="filter.provider_id"
                value-field="id"
                text-field="name"
                :placeholder="$t('Поставщик')"
                :options="providers.data"
                @change="getItems"
              />
            </el-form-item>
          </el-col>
          <el-col
            :md="8"
            :lg="8"
            class="d-flex"
          >
            <el-form-item
              class="mr-1"
              label="Дата начала"
            >
              <x-date-picker
                v-model="filter.from_date"
                :clearable="false"
                :placeholder="$t('Дата начала')"
                @input="getItems"
              />
            </el-form-item>
            <el-form-item
              class="mr-1"
              label="Дата окончания"
            >
              <x-date-picker
                v-model="filter.to_date"
                :clearable="false"
                :placeholder="$t('Дата окончания')"
                @input="getItems"
              />
            </el-form-item>
          </el-col>
          <!-- <el-col
            :md="8"
            :lg="6"
          >
            <el-form-item
              class="mr-1"
              label="Дата окончания"
            >
              <x-date-picker
                v-model="filter.to_date"
                :clearable="false"
                :placeholder="$t('Дата окончания')"
              />
            </el-form-item>
          </el-col> -->
          <el-col
            :md="4"
            :lg="4"
          >
            <el-form-item
              class="mr-1"
              label="Oralig'i"
            >
              <b-form-select
                v-model="filter.date_filter_range"
                value-field="value"
                text-field="label"
                :options="sortDates"
                @change="setRange"
              />
            </el-form-item>
          </el-col>
        </template>
        <template v-else>
          <el-col
            :md="8"
            :lg="6"
          >
            <el-form-item
              class="mr-1"
              label="Tartiblash"
            >
              <b-form-select
                v-model="filter.order_by_quantity"
                value-field="value"
                text-field="label"
                :options="ordersList"
                @change="getItems"
              />
            </el-form-item>
          </el-col>
        </template>
        <el-col
          :md="8"
          :lg="6"
        >
          <el-form-item
            class="mr-1"
            label="Omborni tanlang"
          >
            <b-form-select
              v-model="filter.warehouse_id"
              value-field="id"
              text-field="name_uz"
              :options="warehouses.data"
              @change="getItems"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          :md="8"
          :lg="6"
        >
          <el-form-item
            class="mr-1"
            label="Номенклатура"
          >
            <v-select
              id="category_id"
              v-model="filter.parent0_category_id"
              :options="categoriesLevel0"
              :reduce="(option) => option.id"
              class="w-100 bg-white"
              style="width: 100%"
              placeholder="Категория"
              label="name_uz"
              @input="parent0change($event)"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="filter.parent0_category_id"
          :md="8"
          :lg="6"
        >
          <el-form-item
            class="mr-1"
            label="Подкатегория"
          >
            <v-select
              id="category_id1"
              v-model="filter.parent1_category_id"
              :options="categoriesLevel1"
              :reduce="(option) => option.id"
              class="bg-white w-100"
              label="name_uz"
              placeholder="Подкатегория"
              @input="parent1change($event)"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="filter.parent1_category_id"
          :md="8"
          :lg="6"
        >
          <el-form-item
            class="mr-1"
            label="Номенклатура"
          >
            <v-select
              id="category_id2"
              v-model="filter.parent2_category_id"
              :options="categoriesLevel2"
              :reduce="(option) => option.id"
              placeholder="Номенклатура"
              class="bg-white w-100"
              label="name_uz"
              @input="parent2change($event)"
            />
          </el-form-item>
        </el-col>
        <el-col
          v-if="reportType == 'residualReport'"
          :md="8"
          :lg="6"
        >
          <label label="v-phone-1">Nomi bo'yicha qidiruv</label>
          <b-form-input
            id="v-phone-1"
            v-model="filter.name_uz"
            placeholder="Nomi bo'yicha qidiruv"
            @input="getItems"
          />
        </el-col>
        <el-col
          :md="3"
          :lg="3"
        >
          <el-form-item
            class="mr-1"
            label=" "
          >
            <b-button
              variant="primary"
              class="btn-tour-skip mr-1"
              @click="getItems"
            >
              <feather-icon
                icon="SearchIcon"
                size="16"
              />
              <span class="mr-25 align-middle"> Искать</span>
            </b-button>
          </el-form-item>
        </el-col>
        <el-col
          :md="2"
          :lg="2"
        >
          <el-form-item
            class="mr-1"
            label=" "
          >
            <b-button
              variant="outline-primary"
              class="btn-tour-skip mr-1"
              @click="clearFilter"
            >
              <feather-icon
                icon="XIcon"
                size="16"
              />
            </b-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- table -->
    <b-overlay :show="loading">
      <template v-if="reportType == 'chashSoldReport'">
        <ProductCashTable ref="cashtable" />
      </template>
      <template v-else-if="reportType == 'priceProductsReport'">
        <PriceProductReportTable ref="cashtable" />
      </template>
      <table
        v-else
        ref="table"
        class="table table-bordered bg-white"
      >
        <thead>
          <tr>
            <th>№</th>
            <template v-if="reportType == 'exemplaryClientsReport'">
              <th>FIO</th>
              <th>Telefon</th>
              <th>Jami to'lov soni</th>
              <th>Kechiktirilgan to'lovlar soni</th>
            </template>
            <template v-else>
              <th>Номенклатура</th>
              <th>
                <div class="d-flex">
                  Количество
                  <div
                    class=" d-flex flex-column"
                    @click="sortByCount"
                  >
                    <feather-icon
                      icon="ChevronUpIcon"
                      size="12"
                    />
                    <feather-icon
                      style="margin-top: -6px;"
                      icon="ChevronDownIcon"
                      size="12"
                    />
                  </div>
                </div>
              </th>
              <th v-if="reportType !== 'residualReport'">
                Цена(USD)
              </th>
            </template>
            <template v-if="reportType == 'residualReport'">
              <th>Цена продажа (USD)</th>
              <th>Цена рассрочка (USD)</th>
              <th>Цена прихода (USD)</th>
            </template>
            <th v-if="reportType == 'residualReport'">
              Все количество прихода
            </th>
            <template v-if="reportType == 'notSoldReport'">
              <th>Цена продажа (USD)</th>
              <th>Дата прихода</th>
              <th>Дата последней продажи</th>
              <th>Количество дней</th>
            </template>
          </tr>
        </thead>
        <tbody v-if="items.length">
          <tr
            v-for="(product, index) in items"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <template v-if="reportType == 'exemplaryClientsReport'">
              <td>
                <router-link
                  :to="{
                    name: 'payment-show',
                    params: { id: product.contract_id },
                  }"
                >
                  <p class="fs12">
                    {{
                      product.lastname +
                        " " +
                        product.firstname +
                        " " +
                        product.middlename
                    }}<br>
                    <i class="fs14">
                      {{ product.doc_series + product.doc_number }} /
                      {{ product.pin }}</i>
                  </p>
                </router-link>
              </td>
              <th>+998 {{ product.phone }}</th>
              <th>{{ product.total_count }}</th>
              <th>{{ product.late_count }}</th>
            </template>
            <template v-else>
              <td>
                <p class="fs12">
                  {{
                    product.parent0_name_uz +
                      "/" +
                      product.parent1_name_uz +
                      "/" +
                      product.parent2_name_uz +
                      "/"
                  }}
                  <b
                    class="fs14 link"
                    @click="openModal(product.category_id)"
                  > {{ product.name_uz }}</b>
                </p>
              </td>
              <td>
                <span
                  class="link cursor-pointer"
                  @click="openModal(product.category_id)"
                >
                  {{
                    product.total_quantity
                      ? product.total_quantity
                      : product.quantity
                  }}
                </span>
              </td>
            </template>
            <td
              v-if="
                reportType !== 'residualReport' &&
                  reportType !== 'exemplaryClientsReport'
              "
            >
              <span v-if="reportType == 'allReport'">
                {{ product.total_cost_dollar | formatPrice }} USD
              </span>
              <span v-else>
                {{ product.total_price_dollar | formatPrice }} USD
              </span>
            </td>
            <template v-if="reportType == 'residualReport'">
              <td>
                {{ product.price_dollar | formatPrice }}
              </td>
              <td>
                {{ product.price_dollar_installment | formatPrice }}
              </td>
              <td>
                {{ product.cost_dollar | formatPrice }}
              </td>
            </template>
            <th v-if="reportType == 'residualReport'">
              {{ product.all_income_quantity | formatPrice }}
            </th>
            <template v-if="reportType == 'notSoldReport'">
              <td>
                {{ product.price_dollar | formatPrice }}
              </td>
              <td>
                {{ formatDateYmd(product.income_date) }}
              </td>
              <td>
                {{
                  product.last_sold_date
                    ? formatDateYmd(product.last_sold_date)
                    : "-"
                }}
              </td>
              <td>
                {{ product.days_count }}
              </td>
            </template>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
              class="text-center"
            >
              Нет информации
            </td>
          </tr>
        </tbody>
      </table>
      <!-- excel goodclients -->
      <table
        v-show="false"
        ref="tableGoodClients"
        class="table table-bordered bg-white"
      >
        <thead>
          <tr>
            <th>№</th>
            <th>FIO</th>
            <th>Telefon</th>
            <th>Jami to'lov soni</th>
            <th>Kechiktirilgan to'lovlar soni</th>
          </tr>
        </thead>
        <tbody v-if="items.length">
          <tr
            v-for="(product, index) in itemsGoodClients"
            :key="index"
          >
            <td>{{ index + 1 }}</td>
            <td>
              <router-link
                :to="{
                  name: 'payment-show',
                  params: { id: product.contract_id },
                }"
              >
                <p class="fs12">
                  {{
                    product.lastname +
                      " " +
                      product.firstname +
                      " " +
                      product.middlename
                  }}<br>
                  <i class="fs14">
                    {{ product.doc_series + product.doc_number }} /
                    {{ product.pin }}</i>
                </p>
              </router-link>
            </td>
            <th>+998 {{ product.phone }}</th>
            <th>{{ product.total_count }}</th>
            <th>{{ product.late_count }}</th>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
              class="text-center"
            >
              Нет информации
            </td>
          </tr>
        </tbody>
      </table>
      <!--  -->
    </b-overlay>
    <!-- {{ goodClientsList }} -->
    <template v-if="goodClientsList">
      <b-pagination
        v-model="filterClients.page"
        :total-rows="goodClientsList.total"
        :per-page="filterClients.per_page"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </template>

    <!--  -->
    <b-modal
      id="modal-lg"
      v-model="showPartyModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      no-close-on-backdrop
      centered
      @close="showPartyModal = false"
    >
      <div class="hello">
        <WarehouseProductInfo
          :p-category-id="pCategoryId"
          :is-component="true"
        />
      </div>
    </b-modal>

    <!-- Residuals -->
    <b-modal
      id="modal-lg"
      v-model="showResidualsModal"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
      title="Omborlar bo'yicha mahsulotlar"
      no-close-on-backdrop
      centered
      @close="showResidualsModal = false"
    >
      <div class="hello">
        <ResidualsTable
          :p-category-id="pCategoryId"
          :is-component="true"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { showToast } from '@/utils/toast'
// import Save from './save.vue'
import CategoryFilter from '@/components/categoryFilter.vue'
import ProductCashTable from '@/components/tables/productCashs.vue'
import PriceProductReportTable from '@/components/tables/priceProductReport.vue'
import vSelect from 'vue-select'
import ResidualsTable from './components/residualsTable.vue'

export default {
  name: 'Index',
  components: {
    CategoryFilter,
    ProductCashTable,
    PriceProductReportTable,
    ResidualsTable,
    vSelect,
    WarehouseProductInfo: () => import('@/views/warehouse/products/warehouseProductInfo.vue'),
  },
  data() {
    return {
      page: 1,
      loading: false,
      items: [],
      reportType: 'allReport',
      showPartyModal: false,
      showResidualsModal: false,
      pCategoryId: null,
      loadingBtn: false,
      filter: {
        provider_id: null,
        category_id: null,
        category_name: '',
        from_date: null,
        to_date: null,
        date_filter_range: '',
        order_by_quantity: '',
        warehouse_id: null,
        sort_key: '',
        sort_type: '',
        name_uz: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
      },
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      filterClients: {
        per_page: 50,
        page: 1,
      },
      goodClientsList: null,
      itemsGoodClients: null,
      sortDate: null,
      sortDates: [
        { label: 'Bugun', value: 'today' },
        { label: 'Kecha', value: 'yesterday' },
        { label: 'Joriy hafta', value: 'week' },
        { label: 'Joriy oy', value: 'month' },
        { label: 'Joriy kvartal', value: 'quarter' },
        { label: 'Joriy yil', value: 'year' },
        { label: "Oralig'i", value: null },
      ],
      ordersList: [
        { label: "Kamayish bo'yicha", value: 'desc' },
        { label: "O'sib borish bo'yicha", value: 'asc' },
      ],
      //
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    ...mapGetters({
      providers: 'providers/GET_ITEMS',
      warehouses: 'warehouse/GET_ITEMS',
    }),
    title() {
      switch (this.reportType) {
        case 'allReport':
          return 'Отчёт по всем продуктам'
        case 'residualReport':
          return 'Отчет об остатках'
        case 'notSoldReport':
          return 'Отчет о непроданных товарах'
        case 'exemplaryClientsReport':
          return 'Отчет об «образцовых клиентах»'
        case 'priceProductsReport':
          return 'Kirim qilingan mahsuloatlar'
        default:
          return 'Отчет по всем проданным товарам'
      }
    },
  },
  watch: {
    'filterClients.page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterClients.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    reportType(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.changeQuery('type', newVal)
        if (newVal == 'allReport') {
          this.$route.meta.pageTitle = 'Отчёт по всем продуктам'
        } else if (newVal == 'residualReport') {
          this.$route.meta.pageTitle = 'Отчет об остатках'
          this.getWarehouseItems()
        } else if (newVal == 'notSoldReport') {
          this.$route.meta.pageTitle = 'Отчет о непроданных товарах'
        } else if (newVal == 'exemplaryClientsReport') {
          this.$route.meta.pageTitle = 'Отчет об «образцовых клиентах»'
        } else if (newVal == 'priceProductsReport') {
          this.$route.meta.pageTitle = 'Kirim qilingan mahsuloatlar'
        } else {
          this.$route.meta.pageTitle = 'Отчет по всем проданным товарам'
        }
      }
    },
    $route(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        if (newVal.query && newVal.query.type) {
          this.reportType = newVal.query.type
        }
        this.getItems()
      }
    },
  },
  mounted() {
    this.getCategoriesByLevel(0)

    if (this.$route.query.parent0_category_id) {
      this.filterModel.parent0_category_id = Number(
        this.$route.query.parent0_category_id,
      )
    }
    if (this.$route.query.parent1_category_id) {
      this.getCategoriesByLevel(
        2,
        Number(this.$route.query.parent1_category_id),
      )
      this.filterModel.parent1_category_id = Number(
        this.$route.query.parent1_category_id,
      )
    }
    if (this.$route.query.parent2_category_id) {
      this.filterModel.parent2_category_id = Number(
        this.$route.query.parent2_category_id,
      )
      this.getCategoriesByLevel(
        3,
        Number(this.$route.query.parent2_category_id),
      )
    }
    if (this.$route.query && this.$route.query.type) {
      this.reportType = this.$route.query.type
      if (this.$route.query.type == 'allReport') {
        this.$route.meta.pageTitle = 'Отчёт по всем продуктам'
      } else if (this.$route.query.type == 'residualReport') {
        this.$route.meta.pageTitle = 'Отчет об остатках'
      } else {
        this.$route.meta.pageTitle = 'Отчет по всем проданным товарам'
      }
    }
    this.getItems()
    this.getProviders()
    this.getWarehouseAction()
  },
  methods: {
    ...mapActions({
      getCategories: 'productCategory/index',
      allIncomeProducts: 'report/allIncomeProducts',
      allResidualProducts: 'report/allResidualProducts',
      notSoldProducts: 'report/notSoldProducts',
      goodClients: 'report/goodClients',
      getProviders: 'providers/index',
      allSoldProducts: 'report/allSoldProducts',
      getWarehouseAction: 'warehouse/index',
    }),
    async parent0change(event) {
      this.filter.parent1_category_id = null
      this.filter.parent2_category_id = null
      this.filter.page = 1
      this.filter.category_id = event
      this.changeQuery('parent0_category_id', event)
      await this.getCategoriesByLevel(1, event)
    },
    parent1change(event) {
      this.filter.parent2_category_id = null
      this.filter.page = 1
      this.filter.category_id = event
      this.getCategoriesByLevel(2, event)
      this.changeQuery('parent1_category_id', event)
    },
    parent2change(event) {
      this.filter.page = 1
      this.filter.category_id = event
      this.getCategoriesByLevel(3, event)
      this.changeQuery('parent3_category_id', event)
      this.getItems()
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .finally(() => {
          this.getItems()
          this.loading = false
        })
    },
    sortByCount() {
      this.filter.sort_key = 'total_quantity'
      this.filter.sort_type = (this.filter.sort_type && this.filter.sort_type == 'desc') ? 'asc' : 'desc'
      this.getItems()
    },
    async getWarehouseItems() {
      await this.getWarehouseAction({ per_page: 999 })
    },
    openModal(id) {
      if (this.reportType == 'residualReport') {
        this.showResidualsModal = true
      } else {
        this.showPartyModal = true
      }
      this.pCategoryId = id
    },
    clearFilter() {
      this.filter = {
        provider_id: null,
        category_id: null,
        category_name: '',
        from_date: null,
        to_date: null,
        date_filter_range: '',
      }
      this.getItems()
    },
    setFiter(arr) {
      const categoryIds = arr.map(c => c.id)
      const categoryNames = arr.map(c => c.name_uz)
      this.filter.category_id = categoryIds[0]
      this.filter.category_name = categoryNames[0]
      this.getItems()
    },
    async getItems() {
      this.loading = true
      await this.method({ ...this.filter }).then(res => {
        if (this.reportType == 'exemplaryClientsReport') {
          this.items = res.data.data
          this.goodClientsList = res.data
        } else {
          this.items = res.data
        }
      })
      this.loading = false
    },
    method(data) {
      if (this.reportType == 'allReport') return this.allIncomeProducts(data)
      if (this.reportType == 'residualReport') return this.allResidualProducts(data)
      if (this.reportType == 'notSoldReport') return this.notSoldProducts(data)
      if (this.reportType == 'exemplaryClientsReport') return this.goodClients(this.filterClients)
      if (this.reportType == 'priceProductsReport') return this.getCashSold(this.filterClients)
      if (this.reportType == 'chashSoldReport') return this.getCashSold(this.filterClients)
      return this.allSoldProducts(data)
    },
    nameByLocal(nameuz, nameru) {
      if (this.$i18n.locale == 'uz') {
        return nameuz
      }
      return nameru
    },
    getCashSold(data) {
      console.log('Load cash data')
      return new Promise((resolve, reject) => {
        if (data) {
          resolve({ data: [] })
        } else {
          reject('error')
        }
      })
    },
    //
    setRange() {
      switch (this.filter.date_filter_range) {
        case 'today':
          this.filter.from_date = this.today()
          this.filter.to_date = this.today()
          break
        case 'yesterday':
          const d = new Date()
          d.setDate(d.getDate() - 1)
          this.filter.from_date = this.formatDate(d)
          this.filter.to_date = this.formatDate(d)
          break
        case 'week':
          const ddd = this.getMonday(new Date())
          this.filter.from_date = this.formatDate(ddd)
          ddd.setDate(ddd.getDate() + 6)
          this.filter.to_date = this.formatDate(ddd)
          break
        case 'month':
          const dd = new Date()
          const firstDay = new Date(dd.getFullYear(), dd.getMonth(), 1)
          const lastDay = new Date(dd.getFullYear(), dd.getMonth() + 1, 0)
          this.filter.from_date = this.formatDate(firstDay)
          this.filter.to_date = this.formatDate(lastDay)
          break
        case 'quarter':
          {
            const q1 = ['01', '02', '03']
            const q2 = ['04', '05', '06']
            const q3 = ['07', '08', '09']
            const q4 = ['10', '11', '12']
            if (q1.includes(this.today().split('-')[1])) {
              this.filter.from_date = `${this.today().split('-')[0]}-01-01`
              this.filter.to_date = `${this.today().split('-')[0]}-03-31`
            }
            if (q2.includes(this.today().split('-')[1])) {
              this.filter.from_date = `${this.today().split('-')[0]}-04-01`
              this.filter.to_date = `${this.today().split('-')[0]}-06-30`
            }
            if (q3.includes(this.today().split('-')[1])) {
              this.filter.from_date = `${this.today().split('-')[0]}-07-01`
              this.filter.to_date = `${this.today().split('-')[0]}-09-30`
            }
            if (q4.includes(this.today().split('-')[1])) {
              this.filter.from_date = `${this.today().split('-')[0]}-10-01`
              this.filter.to_date = `${this.today().split('-')[0]}-12-31`
            }
          }
          break
        case 'year':
          this.filter.from_date = `${this.today().split('-')[0]}-01-01`
          this.filter.to_date = `${this.today().split('-')[0]}-12-31`
          break
        default:
          this.filter.from_date = `${this.today().split('-')[0]}-01-01`
          this.filter.to_date = null
          break
      }
    },
    today() {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    formatDate(date) {
      const today = new Date(date)
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
      const yyyy = today.getFullYear()
      return `${yyyy}-${mm}-${dd}`
    },
    getMonday(dd) {
      const d = new Date(dd)
      const day = d.getDay()
      const diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },
    //
    tableToExcel(table) {
      if (this.reportType == 'exemplaryClientsReport') {
        this.loadingBtn = true
        this.goodClients({ per_page: 9999 })
          .then(res => {
            this.itemsGoodClients = res.data.data
          })
          .finally(() => {
            setTimeout(() => {
              if (!table.nodeType) table = this.$refs.tableGoodClients
              const ctx = {
                worksheet: `${this.title}`,
                table: table.innerHTML,
              }
              const link = document.createElement('a')
              link.download = `${this.title}.xls`
              link.href = this.uri + this.base64(this.format(this.template, ctx))
              link.click()
              this.loadingBtn = false
            }, 100)
          })
      } else {
        this.loadingBtn = true
        setTimeout(() => {
          if (!table.nodeType) table = this.$refs.table
          const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
          const link = document.createElement('a')
          link.download = `${this.title}.xls`
          link.href = this.uri + this.base64(this.format(this.template, ctx))
          link.click()
          this.loadingBtn = false
        }, 100)
      }
    },
  },
}
</script>

<style scoped></style>
