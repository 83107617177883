<template>
  <div>
    <table
      ref="tableGoodClients"
      class="table table-bordered bg-white"
    >
      <thead>
        <tr>
          <th>№</th>
          <th>Mahsulot ID</th>
          <th>Soni</th>
          <th>Ombor</th>
        </tr>
      </thead>
      <tbody v-if="items.length">
        <tr
          v-for="(product, index) in items"
          :key="index"
        >
          <td>{{ index + 1 }}</td>
          <td>
           {{ product.category_id }}
          </td>
          <th> {{ product.quantity }}</th>
          <th>{{ product.warehouse?product.warehouse.name_uz:'' }}</th>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td
            colspan="5"
            class="text-center"
          >
            Нет информации
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  components: {},
  props: {
    pCategoryId: {
      type: Number,
      default: () => null,
    },
    isComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      items: null,
    }
  },
  computed: {
  },
  mounted() {
    this.getItems()
  },
  methods: {
    ...mapActions({
      residualsByWarehouse: 'report/residualsByWarehouse',
    }),

    async getItems() {
      this.loading = true
      await this.residualsByWarehouse({ category_id: this.pCategoryId }).then(res => {
        this.items = res.data
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
