<template>
  <b-card :title="title">
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="items"
      :rtl="direction"
      :total="total"
      :pagination-options="{
        enabled: true,
        perPage: filter.per_page,
      }"
      @on-column-filter="onColumnFilter"
    >
      <template slot="table-row" slot-scope="props">
        <slot
          name="table-column"
          :row="props.row"
          :column="props.column"
          :index="props.index"
        />
        <span v-if="props.column.field === 'row_number'">
          {{ getRowNumber(props.index) }}
        </span>

        <span v-if="props.column.isAmount" class="text-nowrap">
          {{ props.formattedRow[props.column.field] | formatPrice }}
        </span>
        <span v-else-if="props.column.field === 'overdue_amount'">
          <b-badge
            v-if="props.formattedRow.overdue_amount > 0"
            pill
            variant="light-danger"
            >{{ props.formattedRow.overdue_amount | formatPrice }}</b-badge
          >
          <span v-else>{{ props.formattedRow.overdue_amount }}</span>
        </span>
        <span v-else-if="props.column.field === 'fullname'">
          <b-link
            v-if="props.column.showField && hasShow"
            :to="{
              name: model + '-show',
              params: { id: props.row.contract_id },
            }"
          >
            {{
              [
                props.row.contract.client.lastname,
                props.row.contract.client.firstname,
              ].join(" ")
            }}
          </b-link>
        </span>
        <span v-else-if="props.column.showField && hasShow">
          <b-link
            v-if="
              props.formattedRow[props.column.field] &&
              props.formattedRow[props.column.field] != -1
            "
            :to="{
              name: model + '-show',
              params: { id: props.row.contract_id },
            }"
            >{{ props.formattedRow[props.column.field] }}</b-link
          >
          <span v-else-if="props.row.contract_id == -1">Naqdga sotilgan</span>
        </span>
        <span v-else-if="props.column.showCashField && hasShow">
          <b-link class="link"
            v-if="
              props.formattedRow[props.column.field] &&
              props.formattedRow[props.column.field] != -1
            "
            :to="{ name: model + '-show', params: { id: props.row.id } }"
            >{{ props.formattedRow[props.column.field] }}</b-link
          >
          <span v-else-if="props.row.contract_id == -1">Naqdga sotilgan</span>
        </span>
        <span v-else-if="props.column.field === 'confirm_action'">
          <b-button
            v-if="props.row.status == 0"
            type="submit"
            variant="primary"
            class="mb-2"
            @click="$emit('confirm', props.row.contract_id)"
          >
            Tasdiqlash
          </b-button>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <!-- <template v-if="props.column.isContract">
                <b-dropdown-item
                  v-if="hasEdit"
                  @click="goTo('OrderCreate', _, {pin:props.row.pin})"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Создать договор') }}</span>
                </b-dropdown-item>

              </template>
              <template v-else-if="props.column.isOrders">
                <b-dropdown-item
                  v-if="hasEdit"
                  @click="$router.push({ name: model + '-show', params: { id: props.row.id } })"
                >
                  <feather-icon
                    icon="FilePlusIcon"
                    class="mr-50"
                  />
                  <span>{{ $t('Создать оплата') }}</span>
                </b-dropdown-item>

              </template> -->
              <!-- <template v-else> -->
              <b-dropdown-item v-if="hasEdit" @click="$emit('edit', props.row)">
                <feather-icon icon="Edit2Icon" class="mr-50" />
                <span>Tahrirlash</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="hasDelete"
                @click="$emit('delete', props.row.id)"
              >
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>O'chirish</span>
              </b-dropdown-item>
              <!-- </template> -->
            </b-dropdown>
          </span>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge v-if="props.row.status === 1" pill variant="light-success">{{
            $t("Активный")
          }}</b-badge>
          <b-badge v-if="props.row.status === 0" pill variant="light-warning">{{
            $t("Нективный")
          }}</b-badge>
        </span>
        <span v-else-if="props.column.isDangerAmount" class="text-danger">
          {{ props.row[props.column.field] | formatPrice }}
        </span>
        <span v-else-if="props.column.isDate">
          {{ formatDateYmd(props.formattedRow[props.column.field]) }}
        </span>
        <span v-else-if="props.column.isPaymentStatus">
          <b-badge
            pill
            :variant="props.row.status ? 'success' : 'warning'"
            class="mr-1 ml-auto"
          >
            {{ props.row.status ? "оплачено" : "не оплачено" }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field == 'print_cash'">
          <span class="link" @click="printCheck(props.row.id)"
            >Распечатать</span
          >
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Маълумотлар
              {{ Number(page - 1) * Number(filter.per_page) + 1 }} дан
              {{ Number(page) * Number(filter.per_page) }} гача
              <b>{{ total }}</b>
            </span>
            <b-form-select
              v-model="filter.per_page"
              :options="['10', '20', '50']"
              class="mx-1"
              @input="(value) => (filter.per_page = value)"
            />
            <!-- <span class="text-nowrap "> Саҳифадаги маълумотлар</span> -->
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="filter.per_page"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="onPageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
      <div slot="emptystate">
        <div class="w-100 text-center">
          {{ $t("Маълумот йўқ") }}
        </div>
      </div>
    </vue-good-table>
  </b-card>
</template>

<script>
import {
  BPagination,
  BFormSelect,
  BCard,
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import store from "@/store/index";
import { mapActions } from "vuex";

export default {
  components: {
    BCard,
    BLink,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BButton,
    BDropdown,
    BDropdownItem,
  },
  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
    total: {
      type: Number,
      default() {
        return 0;
      },
    },
    page: {
      type: Number,
      default() {
        return 1;
      },
    },
    modelName: {
      type: String,
      default() {
        return "";
      },
    },
    model: {
      type: String,
      default() {
        return "";
      },
    },
    hasShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasContractShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasEdit: {
      type: Boolean,
      default() {
        return true;
      },
    },
    hasDelete: {
      type: Boolean,
      default() {
        return true;
      },
    },
    title: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      dir: false,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    storagePaymentsUrl() {
      return `${process.env.VUE_APP_BASE_URL}storage/payments/`;
    },
  },
  methods: {
    ...mapActions({ makePaymentPdf: "resource/makePaymentPdf" }),
    onColumnFilter(params) {
      this.columns
        .filter((item) => item.filterOptions && item.filterOptions.enabled)
        .forEach((item) => {
          let { field } = item;
          if (typeof field === "function") {
            field = item.filterField;
          }
          if (item.filterOptions.filterDropdownItems) {
            const splitted = field.split(".");
            const newField =
              splitted.length === 1 ? splitted[0] : `${splitted[0]}_id`;
            this.filter[newField] = params.columnFilters[field]
              ? params.columnFilters[field]
              : null;
          } else {
            this.filter[field] = params.columnFilters[item.field]
              ? params.columnFilters[item.field]
              : null;
          }
        });
      this.$emit("getItems");
    },
    onPageChange(page) {
      this.$emit("onPageChange", page);
    },
    getRowNumber(index) {
      return (this.page - 1) * this.filter.per_page + index + 1;
    },
    printCheck(id) {
      this.makePaymentPdf(id).then((res) => {
        this.contract_path = `${this.storagePaymentsUrl + res.data}.pdf`;
        window.open(`${this.storagePaymentsUrl + res.data}.pdf`, "_blank");
        // this.downloadFile()
        // this.downloadAxiosFile()
      });
    },
  },
};
</script>
