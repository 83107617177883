2<template>
  <div class="d-flex flex-column">
    <div class="d-flex justify-content-between align-items-center p-1">
      <h2>Kirim qilingan mahsuloatlar ro'yhati <b v-if="contracts && contracts.total">({{ contracts.total }} ta)</b></h2>
      <b-overlay :show="loadingBtn">
        <b-button
          variant="outline-success"
          class="float-right"
          size="small"
          @click="tableToExcel"
        >
          <img
            src="/images/excel_1.svg"
            alt=""
          >
        </b-button>
      </b-overlay>
    </div>
    <div>
      <b-row>
        <b-col
          :md="7"
          class="d-flex flex-wrap flex-row"
        >
          <x-date-picker
            v-model="filterModel.from_date"
            :placeholder="$t('Дата начала')"
            class="mr-1 mb-1"
          />
          <x-date-picker
            v-model="filterModel.to_date"
            :placeholder="$t('Дата окончания')"
            class="mr-1 mb-1"
          />
        </b-col>
      </b-row>

      <el-row
        class="mb-2"
        :gutter="20"
      >
        <el-col
          :md="8"
          :lg="6"
        >
          <label label="category_id">Категория </label>
          <v-select
            id="category_id"
            v-model="filterModel.parent0_category_id"
            :options="categoriesLevel0"
            :reduce="(option) => option.id"
            class="w-100"
            style="width: 100%"
            placeholder="Категория"
            label="name_uz"
            @input="parent0change($event)"
          />
        </el-col>
        <el-col
          v-if="filterModel.parent0_category_id"
          :md="8"
          :lg="6"
        >
          <label label="category_id1">Подкатегория </label>
          <v-select
            id="category_id1"
            v-model="filterModel.parent1_category_id"
            :options="categoriesLevel1"
            :reduce="(option) => option.id"
            class="w-100"
            label="name_uz"
            placeholder="Подкатегория"
            @input="parent1change($event)"
          />
        </el-col>
        <el-col
          v-if="filterModel.parent1_category_id"
          :md="8"
          :lg="6"
        >
          <label label="category_id2">Номенклатура </label>
          <v-select
            id="category_id2"
            v-model="filterModel.parent2_category_id"
            :options="categoriesLevel2"
            :reduce="(option) => option.id"
            placeholder="Номенклатура"
            class="w-100"
            label="name_uz"
            @input="parent2change($event)"
          />
        </el-col>
        <el-col
          v-if="filterModel.parent2_category_id"
          :span="6"
        >
          <label label="category_id2">Mahsulot nomi </label>

          <v-select
            id="category_id2"
            v-model="filterModel.category_id"
            :options="categoriesLevel3"
            :reduce="(option) => option.id"
            label="name_uz"
            @input="getItems"
          />
        </el-col>
        <el-col
          :md="8"
          :lg="6"
        >
          <label label="warehouse">Поставщик </label>
          <v-select
            id="category_id"
            v-model="filterModel.provider_id"
            :options="providers.data"
            :reduce="(option) => option.id"
            class="w-100"
            style="width: 100%"
            placeholder="Поставщик"
            label="name"
            @input="getItems"
          />
        </el-col>
        <!-- <el-col
          :md="8"
          :lg="6"
        >
          <label label="v-phone-1">Nomi bo'yicha qidiruv</label>
          <b-form-input
            id="v-phone-1"
            v-model="filterModel.name_uz"
            placeholder="Nomi bo'yicha qidiruv"
            @input="getItems"
          />
        </el-col> -->
      </el-row>
    </div>
    <b-overlay
      v-if="contracts"
      :show="loading"
    >
      <good-table-contracts
        :items="contracts.data"
        :columns="columns"
        :total="contracts.total"
        :page="page"
        :has-show="true"
        model="CashOrder"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'productsList'">
            <div v-if="props.row.products && props.row.products.length">
              <p
                v-for="(item, i) in props.row.products"
                :key="item.id"
              ><b>{{ i + 1 }}. </b> {{ item.category.name_uz }}
              </p>
            </div>
          </span>
        </template>
      </good-table-contracts>
      <good-table-contracts
        v-if="contractsX"
        v-show="false"
        ref="table"
        :items="contractsX.data"
        :columns="columns"
        :total="contractsX.total"
        :page="page"
        :has-show="true"
        class="tablee"
        model="CashOrder"
        :filter="filterModel"
        @getItems="getItems"
        @onPageChange="(p) => (page = p)"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'productsList'">
            <div v-if="props.row.products && props.row.products.length">
              <p
                v-for="(item, i) in props.row.products"
                :key="item.id"
              ><b>{{ i + 1 }}. </b> {{ item.category.name_uz }}
              </p>
            </div>
          </span>
        </template>
      </good-table-contracts>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import GoodTableContracts from '@/views/table/vue-good-table/GoodTableContracts.vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    GoodTableContracts,
    vSelect,
  },
  props: {
    // contracts: {
    //   type: [Array, Object],
    //   default: () => null,
    // },
  },
  data() {
    return {
      page: 1,
      loading: false,
      statusTab: 1,
      contracts: null,
      contractsX: null,
      loadingBtn: false,
      categoriesLevel0: [],
      categoriesLevel1: [],
      categoriesLevel2: [],
      categoriesLevel3: [],
      filterModel: {
        per_page: 50,
        type: 3,
        relations: 'party.provider|category',
        name_uz: null,
        category_id: null,
        parent0_category_id: null,
        parent1_category_id: null,
        parent2_category_id: null,
        category_name: '',
        from_date: null,
        to_date: null,
      },
      title: 'Kirim qilingan mahsuloatlar',
      uri: 'data:application/vnd.ms-excel;base64,',
      template:
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><meta charset="utf-8"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64(s) {
        return window.btoa(unescape(encodeURIComponent(s)))
      },
      format(s, c) {
        return s.replace(/{(\w+)}/g, (m, p) => c[p])
      },
    }
  },
  computed: {
    ...mapGetters({
      providers: 'providers/GET_ITEMS',
    }),
    columns() {
      return [
        {
          label: '#',
          field: 'row_number',
        },
        {
          label: 'Поставщик',
          field: 'party.provider.name',
        },
        {
          label: 'Kelgan sana',
          field: 'party.date',
          isDate: true,
        },
        {
          label: 'Mahsulot nomi',
          field: 'category.name_uz',
        },
        {
          label: 'Soni',
          field: 'quantity',
        },
        {
          label: 'Summa(USD)',
          field: 'cost',
          class: 'text-nowrap',
          isAmount: true,
        },
        {
          label: 'Summa(UZS)',
          field: 'cost_dollar',
          class: 'text-nowrap',
          isAmount: true,
        },
      ]
    },
  },
  watch: {
    page(newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.per_page': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.from_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
    'filterModel.to_date': function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) this.getItems()
    },
  },
  mounted() {
    this.getCategoriesByLevel(0)

    if (this.$route.query.parent0_category_id) {
      this.filterModel.parent0_category_id = Number(
        this.$route.query.parent0_category_id,
      )
    }
    if (this.$route.query.parent1_category_id) {
      this.getCategoriesByLevel(
        2,
        Number(this.$route.query.parent1_category_id),
      )
      this.filterModel.parent1_category_id = Number(
        this.$route.query.parent1_category_id,
      )
    }
    if (this.$route.query.parent2_category_id) {
      this.filterModel.parent2_category_id = Number(
        this.$route.query.parent2_category_id,
      )
      this.getCategoriesByLevel(
        3,
        Number(this.$route.query.parent2_category_id),
      )
    }
    this.getProviders()
    this.getItems()
  },
  methods: {
    ...mapActions({
      getCategories: 'productCategory/index',
      getProviders: 'providers/index',
      getAction: 'report/priceProductsReport',
    }),
    async parent0change(event) {
      this.filterModel.parent1_category_id = null
      this.filterModel.parent2_category_id = null
      this.filterModel.page = 1
      this.changeQuery('parent0_category_id', event)
      await this.getCategoriesByLevel(1, event)
    },
    parent1change(event) {
      this.filterModel.parent2_category_id = null
      this.filterModel.page = 1
      this.getCategoriesByLevel(2, event)
      this.changeQuery('parent1_category_id', event)
    },
    parent2change(event) {
      this.filterModel.page = 1
      this.getCategoriesByLevel(3, event)
      this.changeQuery('parent3_category_id', event)
      this.getItems()
    },
    getCategoriesByLevel(level, parentId = null) {
      this.loading = true
      this.getCategories({ level, parent_id: parentId, per_page: 9999 })
        .then(res => {
          this[`categoriesLevel${level}`] = res.data.data
        })
        .finally(() => {
          this.getItems()
          this.loading = false
        })
    },
    passportField(row) {
      if (row.products && row.products.length) {
        return row.products.map(el => el.category.name_uz)
      }
      return ''
    },
    async getItems() {
      this.loading = true
      await this.getAction({
        ...this.filterModel,
        page: this.page,
      }).then(res => {
        this.contracts = res.data
      })
      this.loading = false
    },
    async getItemsX() {
      this.loading = true
      await this.getAction({
        ...this.filterModel,
        per_page: 9999,
      }).then(res => {
        this.contractsX = res.data
      })
      this.loading = false
    },
    async tableToExcel(table) {
      this.loadingBtn = true
      await this.getItemsX()

      const tableeee = document.querySelector(
        'div.tablee div.vgt-responsive table',
      )

      const rows = tableeee.querySelectorAll('table tr')
      rows.forEach(row => {
        const lastCell = row.lastElementChild
        if (lastCell.querySelector('td.vgt-left-align span.link')) {
          lastCell.remove()
        }
      })

      setTimeout(() => {
        if (!table.nodeType) table = tableeee
        const ctx = { worksheet: `${this.title}`, table: table.innerHTML }
        const link = document.createElement('a')
        link.download = `${this.title}.xls`
        link.href = this.uri + this.base64(this.format(this.template, ctx))
        link.click()
        this.loadingBtn = false
      }, 100)
    },
  },
}
</script>

<style scoped></style>
